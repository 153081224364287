import React from 'react'
import { graphql } from "gatsby"
import Page from '../components/Pages/Page';
import Port from '../components/Pages/Port';

function PortTemplate(props) {
    
    return (
        <Page {...props} PageComponent={ Port } />
    )

}

export const queryPort = graphql`
fragment portFields on NewSites {
    ...globalFields
    pages {
        type
        id
        aUri
        content {
            h1
            subTitle
            text
            images
            seoTitle
            seoDescription
 
        }
    }
}
query portQuery($subDomain: String) {
    newSites(subDomain: {eq: $subDomain}) {
        ...portFields
    }
}`

export default PortTemplate


