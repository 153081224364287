import React from 'react'
import { Container, Row, Col} from "react-bootstrap";
import Seo from '../seo'
import { renderSingleImage } from '../../utils/images';
import Breadcrumbs from "../Blocks/Breadcrumbs"

function Port(props) {

   let parent = props.pages.filter((page) => page.type === 'portfolio')[0]

    return (
        <>
        <Seo
            title={props.content.seoTitle || props.content.h1}
            description={props.content.seoDescription}
            metas={props.global.defaults.metas}
        />
         <Breadcrumbs home={props.global.defaults.lang.home} contentH1={props.content.h1} aUri={props.page.aUri} type='port' parentAUri={parent.aUri} parentH1={parent.content.h1}/>

        <Container style={{maxWidth: 1240, minHeight: 500}}>
        <div id='h1p'>
          <h1 style={{textAlign: 'center'}}>{props.content.h1}</h1>
          <p style={{textAlign: 'center'}}>{props.content.subTitle || ''}</p>
        </div>
        <Row>
          <Col sm={8}>
            <Row>
    
            {props.content.images && props.content.images.map(img => {
           
              return (

                <Col sm={6} style={{marginTop: 30}} data-aos="fade-up">
                   {renderSingleImage(props.localImages, img)}
                </Col>
              )
        
            })}
            </Row>
          </Col>
          <Col sm={4}>
          <div style={{marginTop: 30}} dangerouslySetInnerHTML={{ __html: props.content.text}} />
          </Col>
        </Row>

      </Container>
      </>
    )
}

export default Port